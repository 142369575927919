<template>
  <div class="pages">
    <div class="houses-list">
      <router-link :to="`/HousesShowInfo?id=${$route.query.id}&index=${index}`" class="list" v-for="(item,index) in project?.house_type" :key="index">
        <div class="pic">
          <img :src="item.img" alt="">
        </div>
        <div class="title">
          <span>{{item.title}}</span>
        </div>
        <div class="price">
          <span class="pr">建筑面积</span>
          <span>约{{item.area}}㎡</span>
        </div>
      </router-link>
    </div>
<!--    <div class="paging-block">-->
<!--      <div class="pre">-->
<!--        <span>上一页</span>-->
<!--      </div>-->
<!--      <div class="page-num">-->
<!--        <div class="num">-->
<!--          <span>1</span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="pre">-->
<!--        <span>下一页</span>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
name: "HousesShow",
  data() {
    return {
      id:0,
      project: false
    }
  },
  mounted() {
  this.id = this.$route.query.id
    this.fetchData()
  },
  methods: {
    async fetchData() {
      let res = await this.http({
        url: '/api/report/projectInfo',
        method: 'GET',
        params: {
          project_id: this.$route.query.id
        }
      })

      res.data.base_data.primary_tag = res.data.base_data.primary_tags[0]?.tag || null

      this.project = res.data
    }
  }
}
</script>

<style scoped lang="scss">
  @import "HousesShow.scss";
</style>
